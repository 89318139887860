<template>
	<div class="shopping-cart">
		<!--编辑-->
		<header class="df fdr alc jc-sb border-bottom-1px" v-if="list.length > 0">
			购物车共{{ total }}件商品
			<span v-if="!is_edit" class="edit-btn" @click.stop="showEdit">编辑</span>
			<span v-if="is_edit" class="edit-btn" @click.stop="saveEdit">完成</span>
		</header>
		<!--商品列表-->
		<yd-infinitescroll v-if="list.length > 0" :callback="getShopCartList" ref="carLists" style="padding-bottom: .2rem;">
			<div class="goods-list" slot="list">
				<div v-for="(i, idx) in list" :key="idx">
					<!--失效商品-->
					<div class="title" v-if="i.isuse === 0 && i.goods_list > 0">失效商品</div>
					<div class="goods-item df fdr alc"
						v-for="(item, index) in i.goods_list"
						:key="item.id"
						:data-type="active_item == index && now_isuse == i.isuse ? 1 : 0">
						<div @touchstart.capture="touchStart"
							:data-index="index"
							@touchend.capture="touchEnd"
							class="touch-item df fdr"
							:data-isuse="i.isuse">
							<div class="select-box flex-center">
									<input
										type="checkbox"
										:id="item.id"
										:disabled="i.isuse===0 && !is_edit"
										v-model="checklist"
										:value="item"
									/>
									<label :for="item.id">
										<icon-svg class="main-color select-checked" icon-class="yi_icon_xuanze" />
									</label>
							</div>
							<div class="right df fdr alc border-bottom-1px" @click="toDetail(item)">
								<img class="goods-img" :src="item.small_pic | setHttps" alt="goods_img" />
								<!--商品信息-->
								<div class="good-inf">
									<div class="goods-info df fdc jc-sb">
										<div>
											<div class="name">
												<span class="sourceName" v-if="item.source_type">
													{{ getSourceTypes(item.source_type) }}
												</span>
												{{ item.item_name }}
											</div>
											<span class="describe" v-if="item.property">{{ item.property }}</span>
										</div>
										<template v-if="businessType == 2">
											<div v-if="price_status == 1">
												<div class="price" v-if="i.isuse === 1">
													{{
															item.display_type === 2 || (tripartite_type === 'VUE_APP_ZJG' && item.integral_usage > 0) ?
															`${(item.price - cashPart(item.integral_usage)).toFixed(2)}元+${item.integral_usage}积分` :
															priceShow(item.display_type ,item.price, item.max_integral_percentage)
													}}
												</div>
												<div class="price failure-price" v-else>
													{{
															item.display_type === 2 || (tripartite_type === 'VUE_APP_ZJG' && item.integral_usage > 0) ?
															`${(item.price - cashPart(item.integral_usage)).toFixed(2)}元+${item.integral_usage}积分` :
															priceShow(item.display_type ,item.price, item.max_integral_percentage)
													}}
												</div>
											</div>
										</template>
										<template v-else>
											<div v-if="price_status == 1">
												<div class="price" v-if="i.isuse === 1">￥{{ item.price }}</div>
												<div class="price failure-price" v-else>￥{{ item.price }}</div>
											</div>
										</template>
									</div>
									<!--数量加减-->
									<div
										v-if="i.isuse === 1 && item.display_type !=2 &&　tripartite_type !== 'VUE_APP_ZJG'"
										class="spinner-box df fdr"
									>
										<span ref="minus" @click.stop="minusNum(item)">
											<icon-svg class="minus" icon-class="yi_minus" />
										</span>
										<input type="number" pattern="[0-9]*"
											ref="numInput" v-model="item.number"
											placeholder disabled="true" />
										<span ref="add" @click.stop="addNum(item)">
											<icon-svg class="main-color add" icon-class="yi_add" />
										</span>
										<!--<yd-spinner v-model="item.number" :val="item.id" :callback="changeCart(item)"></yd-spinner>-->
									</div>
								</div>
								<!--失效状态-->
								<div v-if="i.isuse === 0" class="failure-img">
									<img v-if="item.status === '已下架'"
										src="../../assets/icon_yixiajia@3x.png" alt />
									<img v-else-if="item.status === '已售罄'"
										src="../../assets/icon_yigaoqing@3x.png" alt />
									<img v-else src="../../assets/icon_yishixiao.png" alt />
								</div>
							</div>
						</div>
						<div class="delete" @click="deleteTouchItem" :data-id="item.id">删除</div>
					</div>
				</div>
			</div>
			<!-- 数据全部加载完毕显示 -->
			<span slot="doneTip">-没有更多了-</span>
		</yd-infinitescroll>
		<!--结算-->
		<footer v-if="list.length > 0" class="border-top-1px df fdr alc jc-sb" :style="toBottom">
			<!--<div class="select-box df fdr alc jc-fs">
        <input id="all_check" type="checkbox" @click.stop="checkedAll" v-model="checked">
        <label for="all_check"  @click="">
          <icon-svg class="main-color select-checked" icon-class="yi_icon_xuanze"/>
        </label>
        <span class="label-name">全选</span>
      </div>-->
			<div class="check-all df alc">
				<yd-checkbox v-model="checked" color="9c9c9d" shape="circle"
					:change="checkedAll">全选</yd-checkbox>
			</div>
			<div v-if="!is_edit" class="df fdr alc">
				<!-- <template v-if="businessType == 2 && showWay == 1">
					<div class="total">合计：{{ total_price }}</div>
				</template>
				<template v-else>
					<div class="total">合计：{{ total_price }}</div>
				</template> -->
				<div class="total">合计：{{ total_price }}</div>
				<button class="settlement" @click="submitOrder">去结算</button>
			</div>
			<button v-else class="del-btn" @click="deleteItem">删除</button>
		</footer>
		<!--删除确认弹窗-->
		<ConfirmAlert :value="show_toast"
			:is_delete="true" confirmText="您确定要删除已选商品吗？"
			confirmBtnText="确认删除"
			@cancel="cancel" @confirm="confirm"></ConfirmAlert>
		<!-- 空视图 -->
		<div class="empty-box df fdc alc" v-if="list.length === 0 && is_request">
			<img src="../../assets/icon_kong_gouwuche@3x.png" alt />
			购物车里啥也没有～
		</div>
		<!-- S 猜你喜欢 -->
		<yd-infinitescroll :callback="getRecommendList" ref="recommend">
			<div slot="list">
				<recommend-list v-if="list.length === 0 && is_request"
				:itemList="recommendList"
				@click-item="clickItem"></recommend-list>
			</div>
			<div style="height: 1rem;" v-if="list.length === 0 && is_request"></div>
			<!-- 数据全部加载完毕显示 -->
			<span slot="doneTip">- 没有更多了 -</span>
		</yd-infinitescroll>
		<!-- E 猜你喜欢 -->
		<!-- S 底部导航 -->
		<foot-nav v-if="!jpkStatus"></foot-nav>
		<!-- E 底部导航 -->
	</div>
</template>

<script>
import FootNav from '@/components/footer/FootNav.vue';
import ConfirmAlert from '@/components/common/ConfirmAlert';
import RecommendList from '@/components/recommend/RecommendList';
import { changeCartNum, getShopCartList, cancelShopCarts } from '@/services/orderApi';
import { getItemList } from '@/services';
import { baseUrl } from '../../utils/env';
import { priceShow, integralPart, cashPart } from '@/utils/goodsUtil';
import { getPointNum, getStore } from '@/utils/common';
import { orderMixin } from "@/mixins/orderMixin"; 
import { mapState } from 'vuex';
import { zjgKeepAlive } from '@/utils/custom'

export default {
	name: 'ShoppingCart',
	components: {
		FootNav,
		ConfirmAlert,
		RecommendList
	},
	mixins: [orderMixin], 
	data() {
		return {
			is_edit: false, //是否编辑
			checked: false, //是否全选
			show_toast: false, //删除确认弹窗
			del_index: '', //删除的位置
			startX: 0, //滑动开始
			endX: 0, //滑动结束
			active_item: -1, //当前活动的滑块
			now_isuse: null, // 记录当前操作滑块的状态
			checklist: [], //多选列表
			failList: [],
			list: [], //购物车商品列表
			recommendList: [], // 猜你喜欢列表
			is_request: 0,
			page: 1,
			pageSize: 10,
			shop_cart_ids: '',
			total: '', //共多少商品
			jpkStatus: false, //鲸品库商城
			toBottom: {} // 鲸品库样式修改
		};
	},
	computed: {
		/**
		 * @method 已选商品的总额
		 */
		/*total_price: function() {
			if (this.businessType == 2 && this.showWay == 1) {
				let theInt = 0,
					money = 0;
				this.checklist.forEach(item => {
					let number = item.number,
						price = Number.parseFloat(item.price),
						integralRatio = Number.parseFloat(item.max_integral / 100),
						moneyRatio = 1 - integralRatio,
						thePrice = price * moneyRatio,
						theMoney = getPointNum(thePrice) === 0 ? thePrice * 100 : Math.ceil(thePrice * 100),
						moneyPath = theMoney / 100,
						moreInt = Math.ceil((price - moneyPath) * this.integralRate);

					theInt += moreInt * number;
					money += moneyPath * number;
				});
				if (money.toFixed(2) > 0) {
					return theInt + '积分' + money.toFixed(2) + '元';
				} else {
					return theInt + '积分';
				}
			} else {
				let num = 0;
				this.checklist.forEach(item => {
					let number = item.number,
							price = Number.parseFloat(item.price);
					// this.$set(item, 'subtotal', (price * number).toFixed(2));
					// num += parseFloat(item.subtotal);
					num += parseFloat((price * number).toFixed(2));
				});
				return num.toFixed(2);
			}
		},*/

		total_price: function() {
			if (this.businessType == 2) {
				let theInt = 0,
						money = 0;
				this.checklist.forEach( (item) => {
					let number = item.number,
							price = Number.parseFloat(item.price),
							type = item.display_type,
							integral_usage = item.integral_usage;

					switch (type){
						case 0:
								if (this.tripartite_type === 'VUE_APP_ZJG') {
									money += (price - cashPart(integral_usage)).toFixed(2) * number;
									theInt += integral_usage * number;
								} else {
									money += price * number;
								}
								break;
						case 1:
								theInt += integralPart(type, price, 100) * number;
								break;
						case 2:
								money += (price - cashPart(integral_usage)).toFixed(2) * number;
								theInt += integral_usage * number;
								break;
						default:
								break;
					}
				});
				if (money.toFixed(2) > 0) {
						if (theInt > 0) {
								return money.toFixed(2) + '元' +  theInt + '积分';
						} else {
								return money.toFixed(2) + '元';
						}
				} else{
						return theInt + '积分';
				}
			} else {
				let num = 0;
				this.checklist.forEach( (item) => {
						let number = item.number,
								price = Number.parseFloat(item.price);
						this.$set(item, "subtotal", (price * number).toFixed(2));
						num += parseFloat(item.subtotal);
				});
				return num.toFixed(2);
			}
		},

		...mapState({
			businessType: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
			showWay: state => state.config.show_way, // 商品展示方式，1积分，2现金
			price_status: state => state.config.price_status, // 是否展示价格 1展示 0 不展示
			mall_price_status: state => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
			integralRate: state => state.config.integral_rate, // 积分比例
			tripartite_type: state => state.tripartite_type
		})
	},
	watch: {
		//深度 watcher
		checklist: {
			handler: function(val, oldVal) {
				zjgKeepAlive();
				if (!this.is_edit) {
					if (this.list.length > 0 && this.checklist.length === this.list[1].goods_list.length) {
						this.checked = true;
					} else {
						this.checked = false;
					}
				} else {
					if (this.list.length > 0) {
						let size1 = this.list[0].goods_list.length;
						let size2 = this.list[1] ? this.list[1].goods_list.length : 0;
						let size3 = this.list[2] ? this.list[2].goods_list.length : 0;
						let size = Number.parseInt(size1 + size2 + size3);
						if (this.checklist.length === size) {
							this.checked = true;
						}
					} else {
						this.checked = false;
					}
				}
			},
			deep: true
		}
	},
	methods: {
		// 积分3.0显示
		priceShow,

		// 现金转积分
		integralPart,

		// 积分转现金
		cashPart,

		//滑动开始
		touchStart(e) {
			this.startX = e.touches[0].clientX;
		},
		//滑动结束
		touchEnd(e) {
			this.endX = e.changedTouches[0].clientX;
			if (this.startX - this.endX > 30) {
				this.active_item = e.currentTarget.dataset.index;
                this.now_isuse = e.currentTarget.dataset.isuse;
			}
			if (this.startX - this.endX < -30) {
				this.active_item = -1;
			}
			this.startX = 0;
			this.endX = 0;
		},
		//判断当前是否有滑块处于滑动状态
		checkSlide() {
			return this.active_item > -1;
		},
		//滑动删除
		deleteTouchItem(e) {
			this.show_toast = true;
			this.shop_cart_ids = e.currentTarget.dataset.id;
		},
		toDetail(item) {
			this.$router.push({
				name: 'goods-detail',
				query: {
					item_id: item.item_id
				}
			});
		},
		async getShopCartList() {
			try {
				if(this.list.length > 0){
					this.$nextTick( () => {
							this.$refs.carLists.$emit('ydui.infinitescroll.loadedDone');
						});
					return false
				}
				const res = await getShopCartList();
				this.is_request = 1;
				if (res.code === 0) {
					const data = res.data;
					this.total = res.data.length;
					// console.log(this.total);
					console.log(this.checklist);
					let goodList = [];
					this.list = [];
					if (data.length > 0) {
						goodList = data;
						let state = [0, 1, 2]; //0下架，1上架，2已删除（假删除）
						state.map(item => {
							let goodObj = {};
							goodObj.isuse = item; //0下架，1上架，2已删除（假删除）
							goodObj.goods_list = goodList.filter(n => n.isuse == item);
							this.list.push(goodObj);
						});
						this.$nextTick( () => {
							this.$refs.carLists.$emit('ydui.infinitescroll.loadedDone');
						});
					}
				}
			} catch (e) {
				throw e;
			}
		},
		/**
		 * @method 购物车加减
		 * @param item 当前商品
		 *
		 */
		minusNum(item) {
			this.$nextTick(() => {
				/* let num = item.number,
						price = Number.parseFloat(item.price);
				if (num <= 1) {
					this.$dialog.toast({
						mes: '不能再减少了',
						timeout: 1000
					});
					return;

				}
				num = num - 1;
				this.$set(item, 'number', num);
				this.$set(item, 'subtotal', (price * num).toFixed(2));
				console.log(item); */
				this.changeCartNum(item, 2);
			});
		},
		/**
		 * 全选
		 */
		checkedAll: function() {
			let _this = this;
			if (!_this.is_edit) {
				if (this.checked) {
					//实现反选
					_this.checklist = [];
					console.log(_this.checklist);
				} else {
					//实现全选
					_this.checklist = [];
					_this.list[1].goods_list.forEach(function(item) {
						_this.checklist.push(item);
					});
					console.log(_this.checklist);
				}
			} else {
				if (this.checked) {
					//实现反选
					_this.checklist = [];
					console.log(_this.checklist);
				} else {
					//实现全选
					_this.checklist = [];
					_this.list[0].goods_list.forEach(function(item) {
						_this.checklist.push(item);
					});
					_this.list[1].goods_list.forEach(function(item) {
						_this.checklist.push(item);
					});
					_this.list[2].goods_list.forEach(function(item) {
						_this.checklist.push(item);
					});
					console.log(_this.checklist);
				}
			}
		},
		addNum(item) {
			this.$nextTick(() => {
				/* let num = item.number,
						price = Number.parseFloat(item.price);
				num = num + 1;
				this.$set(item, 'number', num);
				this.$set(item, 'subtotal', (price * num).toFixed(2));
				console.log(item); */
				this.changeCartNum(item, 1);
			});
		},

		/* changeCart(item) {
			this.$nextTick(() => {
				this.changeCartNum(item);
				let num = item.number,
					price = Number.parseFloat(item.price);
				this.$set(item, 'subtotal', (price * num).toFixed(2));
			});
		}, */

		// 购物车加减
		async changeCartNum(item, type) {
			let number = item.number;
			if (type == 1) {
				number = number + 1;
			} else {
				number = number - 1;
			}
			if (number <= 0) {
				this.$dialog.toast({
					mes: '不能再减少了',
					timeout: 1000
				});
				return;
			}
			let params = {
				shop_cart_id: item.id, //购物车id
				number: number < 1 ? 1 : number //数量
			};
			try {
				const res = await changeCartNum(params);
				if (res.code === 0) {
					const data = res.data;
					console.log(data);
					if (type == 1) {
						this.$nextTick(() => {
							let num = item.number,
								price = Number.parseFloat(item.price);
								num = num + 1;
							this.$set(item, 'number', num);
							// this.$set(item, 'subtotal', (price * num).toFixed(2));
						});
					} else {
						this.$nextTick(() => {
							let num = item.number,
								price = Number.parseFloat(item.price);
							if (num <= 1) {
								this.$dialog.toast({
									mes: '不能再减少了',
									timeout: 1000
								});
								return;
							}
							num = num - 1;
							this.$set(item, 'number', num);
							// this.$set(item, 'subtotal', (price * num).toFixed(2));
						});
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		//删除
		deleteItem() {
			if (this.checklist.length === 0) {
				this.$dialog.toast({
					mes: '您还没有选择任何商品哦~'
				});
				return;
			} else if (this.checklist.length > 0) {
				this.show_toast = true;
			}
		},
		// 点击取消按钮
		cancel() {
			this.show_toast = false;
		},
		// 删除确认
		async confirm() {
			let shop_cart_ids = '';
			if (this.shop_cart_ids) {
				this.active_item = -1;
				shop_cart_ids = this.shop_cart_ids;
			} else {
				console.log(this.checklist);
				let del_list = [];
				for (let i = 0; i < this.checklist.length; i++) {
					del_list[i] = this.checklist[i].id;
				}
				console.log(del_list.toString());
				shop_cart_ids = del_list.toString();
			}
			try {
				const res = await cancelShopCarts(shop_cart_ids);
				if (res.code === 0) {
					const data = res.data;
					console.log(data);
					this.checklist = [];
					this.checked = false; //是否全选
					this.show_toast = false;
					this.list = [];
					this.is_request = 0;
					this.getShopCartList();
				} else {
					this.$dialog.toast({
						mes: res.msg,
						icon: 'error'
					});
				}
			} catch (e) {
				console.log(e);
			}
		},
		selectGoods(index) {
			if (this.list[index].select == true) {
				this.list[index].select = false;
			} else {
				this.list[index].select = true;
			}
		},
		// 获取猜你喜欢
		async getRecommendList() {
			try {
				let where = {};
				where.order = 'sales';
				where.page = this.page;
				where.listRows = this.pageSize;
				const res = await getItemList(where);
				if (res.code === 0) {
					const { data, total } = res.data;
					this.recommendList = [...this.recommendList, ...data];

					if (this.recommendList.length === total) {
						// 所有数据加载完毕
						this.$refs.recommend.$emit('ydui.infinitescroll.loadedDone');
						return;
					}
					// 单次请求数据完毕
					this.$refs.recommend.$emit('ydui.infinitescroll.finishLoad');
					this.page++;
				} else {
					this.$dialog.toast({
						mes: res.msg,
						timeout: 1500,
						icon: 'error'
					});
				}
			} catch (error) {
				console.warn('Something bad happened: ', error);
			}
		},
		// 点击商品
		clickItem(item) {
			this.$router.push({
				name: 'goods-detail',
				query: {
					item_id: item.id
				}
			});
		},
		submitOrder() {
			if (this.checklist.length === 0) {
				this.$dialog.toast({
					mes: '您还没有选择任何商品哦~'
				});
			} else {
				let cart_list = [];
				for (let i = 0; i < this.checklist.length; i++) {
					cart_list[i] = this.checklist[i].id;
				}
				// console.log(cart_list.toString());

				// window.location.href = `${baseUrl}/#/submit_order?cart_ids=${cart_list.toString()}&add_type=2`;
				this.$router.push({
					name: 'submit_order',
					query: {
						cart_ids: cart_list.toString(),
						add_type: 2,//购物车过来传2，商品详情进来传1
					},

				});
				this.$store.commit('clearCreateOrder');
			}
		},
		//编辑
		showEdit() {
			this.is_edit = true;
			this.checked = false; //是否全选
			this.checklist = [];
		},
		// 保存编辑
		saveEdit() {
			this.is_edit = false;
			this.checked = false; //是否全选
			this.checklist = [];
		},

		// 鲸品库判断
		isJPk() {
			let id = JSON.parse(getStore('businessInfo')).id;
			if (Number(process.env.VUE_APP_JPKID) === id) {
				this.jpkStatus = true;
				this.toBottom = {
					bottom: 0
				};
			}
		}
	},
	activated() {
		// this.getRecommendList();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			if (from.name !== 'goods-detail') {
				sessionStorage.route = vm.$route.name;
				vm.page = 1;
				vm.recommendList = [];
				vm.activeTab = 0;
				vm.$refs.recommend.$emit('ydui.infinitescroll.reInit');
				vm.getRecommendList();
			}
			if (from.name !== 'submit_order') {
				vm.list = [];
				vm.getShopCartList();
				vm.isJPk();
				vm.is_edit = false; //是否编辑
				vm.checklist = [];
				vm.checked = false; //是否全选
				vm.show_toast = false; //删除确认弹窗
				vm.del_index = ''; //删除的位置
				vm.is_request = 0;
				vm.shop_cart_ids = '';
				vm.startX = 0; //滑动开始
				vm.endX = 0; //滑动结束
				vm.active_item = -1; //当前活动的滑块
			}
		});
	}
};
</script>
<style>
.yd-list-donetip {
	margin-bottom: 1rem;
}
</style>
<style lang="less">
@import '../../common/less/variable.less';
@import '../../common/less/mixin.less';

.shopping-cart {
	position: relative;
	/*编辑*/

	header {
		.wh(100%, 0.88rem);
		background: @color-white;
		padding: 0 0.48rem 0 0.24rem;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 4;
		.sc(0.28rem, @color-dark-grey);

		.edit-btn {
			.sc(0.28rem, var(--main-color));
		}
	}

	/*商品列表*/

	.goods-list {
		margin-top: 0.88rem;
		width: 100%;
		overflow: hidden;

		/*加减按钮*/

		.yd-spinner > span:first-child {
			background-color: @color-background-grey;
			border-radius: 0.04rem 0 0 0.04rem;
			border-color: @color-row-line;
		}

		.yd-spinner > span:last-child {
			background-color: @color-background-grey;
			border-radius: 0 0.04rem 0.04rem 0;
			border-color: @color-row-line;
		}

		.yd-spinner > input {
			border-bottom: 1px solid @color-row-line;
			border-top: 1px solid @color-row-line;
			-webkit-appearance: none;
		}

		.yd-spinner > span:first-child > i:after {
			color: #bbb;
		}

		.yd-spinner > span:last-child > i:after {
			color: var(--main-color);
		}

		/*失效商品*/

		.title {
			height: 0.82rem;
			line-height: 0.82rem;
			.sc(0.28rem, @color-dark-grey);
			padding-left: 0.24rem;
			background: @color-background-grey;
		}

		/*商品信息*/

		.goods-item {
			.wh(100%, 2.6rem);
			position: relative;
			transition: all 0.2s;
			background: @color-white;

			&[data-type='0'] {
				transform: translate3d(0, 0, 0);
			}

			&[data-type='1'] {
				transform: translate3d(-1.8rem, 0, 0);
			}

			/*删除按钮*/

			.touch-item {
				width: 100%;
			}

			.delete {
				.wh(1.8rem, 2rem);
				background: @color-add-red;
				.sc(0.28rem, @color-white);
				line-height: 2rem;
				text-align: center;
				position: absolute;
				top: 0;
				right: -1.82rem;
				z-index: 3;
			}

			/*&:last-child {
				margin-bottom: 1rem;
			}*/

			.right {
				.wh(88.8%, 100%);
				padding: 0.6rem 0;
				position: relative;

				.goods-img {
					.wh(1.4rem, 1.4rem);
					margin-right: 0.24rem;
				}

				.goods-info {
					width: 100%;
					height: 100%;
					.sc(0.32rem, @color-dark-grey);

					.name {
						margin-bottom: 0.1rem;
						line-height: 0.4rem;
						width: 90%;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;

						.sourceName {
							position: relative;
							top: -0.05rem;
							display: inline-block;
							height: 0.3rem;
							line-height: 0.35rem;
							padding: 0 0.15rem;
							border-radius: 0.2rem;
							background-color: var(--main-color);
							color: #ffffff;
							font-size: 0.18rem;
						}
					}

					.describe {
						.sc(0.22rem, var(--main-color));
						padding: 0 0.12rem;
						min-width: 1.78rem;
						height: 0.36rem;
						line-height: 0.36rem;

						border-radius: 0.18rem;
						border: 0.02rem solid var(--main-color);
					}

					.price {
						.sc(0.32rem, @color-price-red);
						height: 0.4rem;
						line-height: 0.4rem;
						margin-top: 0.18rem;
					}

					.failure-price {
						color: @color-light-grey-s;
					}
				}
			}

			/*加减*/

			.spinner-box {
				position: absolute;
				top: 2rem;
				right: 0.24rem;
				// bottom: 0.24rem;
				width: 1.88rem;
				border-radius: 4px;
				border: 1px solid rgba(225, 225, 225, 1);

				span {
					.wh(0.48rem, 0.48rem);
					background: #f5f5f5;
					display: flex;
					align-items: center;
					justify-content: center;

					&:first-child {
						border-top-left-radius: 4px;
						border-bottom-left-radius: 4px;
					}

					&:last-child {
						border-top-right-radius: 4px;
						border-bottom-right-radius: 4px;
					}

					.minus {
						color: #bbb;
						width: 0.36rem;
					}

					.add {
						width: 0.36rem !important;
						height: 0.36rem !important;
					}
				}

				input {
					.wh(0.92rem, 0.48rem);
					letter-spacing: 0;
					float: left;
					height: inherit;
					text-align: center;
					color: #666;
					border: none;
					font-size: 0.26rem;
					background-color: #fff;
				}
			}

			/*失效图片*/

			.failure-img {
				position: absolute;
				right: 0;
				bottom: 0;

				img {
					.wh(1.56rem, 1.4rem);
				}
			}
		}
	}

	.select-box {
		width: 11.2%;

		input[type='checkbox'] {
			display: none;
		}

		input[type='checkbox'] + label:before {
			content: '';

			display: inline-block;
			.wh(18px, 18px);
			border: 1px solid @color-light-grey;
			border-radius: 50%;
		}

		input[type='checkbox'] + label {
			.select-checked {
				display: none;
			}
		}

		input[type='checkbox']:checked + label:before {
			position: absolute;
			border: 0;
		}

		input[type='checkbox']:checked + label {
			.select-checked {
				display: block;
			}
		}

		input[type='checkbox']:disabled + label:before {
			content: '';
			display: inline-block;
			.wh(18px, 18px);
			background: #f5f5f5;
			border: 1px solid #e4e4e4;
			border-radius: 50%;
		}

		.select-normal {
			.wh(18px, 18px);
			border: 1px solid @color-light-grey;
			border-radius: 50%;
		}

		.select-checked {
			.wh(18px, 18px);
			display: none;
		}

		.select-disabled {
			.wh(18px, 18px);
			background: #f5f5f5;
			border: 1px solid #e4e4e4;
			border-radius: 50%;
		}
	}

	/*结算*/

	footer {
		.wh(100%, 0.96rem);
		background: @color-white;
		position: fixed;
		bottom: 0.98rem;
		padding-left: 0.24rem;
		z-index: 4;
		/*复选框*/

		.select-box {
			width: 1.31rem;
		}

		label {
			width: auto;
		}

		.label-name {
			.sc(0.28rem, @color-dark-grey);
			line-height: 0.4rem;
			margin-left: 0.16rem;
		}

		.check-all {
			width: 2rem;

			/*.yd-checkbox-icon {
        border: 0.01rem solid @color-light-grey;
        width: 0.36rem !important;
        height: 0.36rem !important;
      }*/

			.yd-checkbox > input[type='checkbox']:checked + .yd-checkbox-icon {
				background-color: var(--main-color);
				border-color: var(--main-color);
				/*width: 0.36rem !important;*/
				/*height: 0.36rem !important;*/
			}

			.yd-checkbox-icon > i {
				width: 0.1rem !important;
				height: 0.2rem !important;
				border-width: 0.04rem;
			}

			.yd-checkbox-text {
				.sc(0.28rem, @color-dark-grey);
				line-height: 0.4rem;
				margin-left: 0.16rem;
			}

			/*span {
        .sc(0.28rem, @color-dark-grey);
        line-height: 0.4rem;
        margin-left: 0.16rem;
      }

      .select-normal {
        .wh(0.36rem, 0.36rem);
        border: 0.01rem solid @color-light-grey;
        border-radius: 50%;
      }

      .select-checked {
        .wh(0.36rem, 0.36rem);
        display: none;
      }

      .select-disabled {
        .wh(0.36rem, 0.36rem);
        background: #f5f5f5;
        border: 0.01rem solid #e4e4e4;
        border-radius: 50%;
      }

      input[type="checkbox"] {
        width: 1rem;
        visibility: hidden;
        position: absolute;

        & + label:before {
          content: '';
          display: inline-block;
          .wh(0.36rem, 0.36rem);
          border: 0.01rem solid @color-light-grey;
          border-radius: 50%;
        }

        & + label {
          .select-checked {
            display: none;
          }
        }

        &:checked + label:before {
          position: absolute;
          border: 0;
        }

        &:checked + label {
          .select-checked {
            display: block;
          }
        }

        &:disabled + label:before {
          content: '';
          display: inline-block;
          .wh(0.36rem, 0.36rem);
          background: #f5f5f5;
          border: 0.01rem solid #e4e4e4;
          border-radius: 50%;
        }
      }*/
		}

		.total {
			.sc(0.32rem, @color-total-price-red);
			margin-right: 0.4rem;
			max-width: 3rem;
		}

		/*结算按钮*/

		.settlement {
			.wh(2.5rem, 0.96rem);
			background: var(--main-color);
			.sc(0.32rem, @color-white);
			line-height: 0.96rem;
			border: 0;

			&:disabled {
				outline: none;
				border: none;
				opacity: 0.4;
			}

			&:active {
				outline: var(--main-color);
			}
		}

		/*删除按钮*/

		.del-btn {
			.wh(2.26rem, 0.68rem);
			border-radius: 0.48rem;
			border: 0.02rem solid @color-price-red;
			.sc(0.28rem, @color-price-red);
			line-height: 0.69rem;
			text-align: center;
			margin-right: 0.24rem;
		}
	}

	/* 列表为空*/

	.empty-box {
		.wh(100%, 4.76rem);
		position: relative;
		.sc(0.28rem, @color-light-grey);

		img {
			.wh(3.64rem, 2.12rem);
			margin: 1.1rem 0 0.4rem;
		}
	}
}
</style>
